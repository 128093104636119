import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styles from './styles.module.scss'

export default class SponsorLogos extends Component {
  constructor(props) {
      super(props)
  }

  renderLogos(data) {
    return (
      (data.sponsorDataJson.sponsors.filter(s => s.type == this.props.sponsorType).length > 0)?
        <div>
          <h3 className={styles.headline}>{this.props.sponsorType} Sponsors</h3>
          <div className={styles.logoRow}>
            {this.renderLogo(data)}
          </div>
        </div>
      : <div></div>
    )
  }

  renderLogo(data) {
    let sponsorData = data.sponsorDataJson.sponsors
    let imageNodes = data.allFile.edges
    return (
      sponsorData.filter(sponsor => sponsor.type == this.props.sponsorType).map((sponsor, index) => {
        let image = imageNodes.filter(image => image.node.name == sponsor.id).shift()
        return (
          <div key={`sponsor-${index}`} className={styles.logo}>
            <a {... sponsor.url ? {href: sponsor.url} : {}}>
              <Img
                fluid={image.node.childImageSharp.fluid}
                alt={sponsor.name} />
            </a>
          </div>
        )
      })
    )
  }

  render() {
    return (
      <StaticQuery
        query = {graphql`
          query SponsorQuery {
            sponsorDataJson {
              sponsors {
                id
                name
                url
                type
              }
            }

            allFile(filter: {
              sourceInstanceName: {in: ["sponsor-logos"]}
              extension: {in: ["gif","jpg","png"]}
            }) {
              edges {
                node {
                  sourceInstanceName
                  name
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        `}
        render = {data => (<div>{this.renderLogos(data)}</div>)}
      />
    )
  }
}