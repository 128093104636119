import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h2>Christian Homeschool Conference of South Dakota</h2>
            </header>
            <div className="content">
                <p><a href="http://www.siouxfallscc.com/plan-your-visit/directions-parking/" target="_blank">Sioux Falls Convention Center</a></p>
                <p>May 03-04, 2024</p>
            </div>
            <div className="major">
                <h3>Holding Fast to Freedom</h3>
                <h4><strong>Galatians 5:1a</strong></h4>
                <h5><i>It is for freedom that Christ set us free, therefore keep standing firm.</i></h5>
            </div>
        </div>
    </section>
)

export default Banner
