import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import SponsorLogos from '../components/sponsor-logos/SponsorLogos'

import pic01 from '../assets/images/backgrounds/pic01.jpg'
import pic02 from '../assets/images/backgrounds/pic02.jpg'
import pic03 from '../assets/images/backgrounds/pic03.jpg'
import pic04 from '../assets/images/backgrounds/pic04.jpg'
import pic05 from '../assets/images/backgrounds/pic05.jpg'
import pic06 from '../assets/images/backgrounds/pic06.jpg'
import pic11 from '../assets/images/backgrounds/pic11.jpg'
import pic12 from '../assets/images/backgrounds/pic12.jpg'

class HomeIndex extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Layout>
                <Helmet
                    title="2024 CHCSD Home"
                    meta={[
                        { name: 'description', content: 'Information homepage for 2024 Christian Homeschool Conference of South Dakota' },
                        { name: 'keywords', content: 'seche, teachsd, chcsd, homeschool conference' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${pic01})`}}>
                            <header className="major">
                                <h3>Speakers</h3>
                                <p>Find out about the exciting and inspiring speakers scheduled at the conference.</p>
                            </header>
                            <Link to="/speakers" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic02})`}}>
                            <header className="major">
                                <h3>Vendors</h3>
                                <p>Find out which vendors will be here to provide advice, resources and opportunities.</p>
                            </header>
                            <Link to="/vendors" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic03})`}}>
                            <header className="major">
                                <h3>Activities</h3>
                                <p>You might be surprised what you & your children can learn at this year's activities.</p>
                            </header>
                            <Link to="/activities" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic04})`}}>
                            <header className="major">
                                <h3>Services</h3>
                                <p>See the available services at this year's conference.</p>
                            </header>
                            <Link to="/services" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic06})`}}>
                            <header className="major">
                                <h3>Schedule</h3>
                                <p>Take a look at the schedule to figure out what presentations, activities and services you want to attend at the conference.</p>
                            </header>
                            <Link to="/schedule" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic05})`}}>
                            <header className="major">
                                <h3>Registration</h3>
                                <p>You've seen enough and are ready to claim your family's spot at the conference? Here's where you go to complete your registration.</p>
                            </header>
                            <Link to="/registration" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic12})`}}>
                            <header className="major">
                                <h3>Lodging</h3>
                                <p>Looking for a place to stay?</p>
                            </header>
                            <Link to="/lodging" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic11})`}}>
                            <header className="major">
                                <h3>About</h3>
                                <p>What is this CHCSD? Who sponsors it? Will there be more like this?</p>
                            </header>
                            <Link to="/about" className="link primary"></Link>
                        </article>
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="minor">
                                <h2> <a href="https://www.chcsd.org/sponsors">Sponsorship Opportunity Details</a></h2>
                            </header>
                            <SponsorLogos sponsorType="gold" key="sponsors-gold" />
                            <SponsorLogos sponsorType="silver" key="sponsors-silver" />
                            <SponsorLogos sponsorType="bronze" key="sponsors-bronze" />
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default HomeIndex
